"use client";

import { useState } from "react";
import SelectList from "./SelectList";
import DefaultButton from "@/components/DefaultButton";
import { usePathname } from "next/navigation";
import PhoneInput from "react-phone-input-2";

const optionsArr = [
    "Россия",
    "Казахстан",
    "Узбекистан",
    "Таджикистан",
    "Кыргызстан",
    "Эстония",
    "Латвия",
    "Литва",
    "Беларусь",
    "Молдова",
    "Украина",
    "Грузию",
    "Азербайджан",
    "Армения",
    "Польша",
    "Германия",
    "США",
    "Турция",
    "Финляндия",
    "ОАЭ",
    "Китай",
    "Корея",
    "Другая",
];

export default function Form() {
    const [values, setValues] = useState({
        name: "",
        country: "",
        phone: "",
        budget: "",
        msg: "",
    });
    const pathname = usePathname();

    return (
        <>
            <div className="form-container">
                <div className="column">
                    <input
                        type="text"
                        placeholder="Имя"
                        value={values.name}
                        id="name"
                        name="name"
                        onChange={changeValue}
                    />
                </div>
                <div className="column">
                    <SelectList
                        options={optionsArr}
                        placeholder="Страна:"
                        onChange={changeOptions}
                    />
                </div>

                <div className="column">
                    {/* <input
                        type="text"
                        placeholder="+7"
                        value={values.phone}
                        id="phone"
                        name="phone"
                        onChange={changeValue}
                    /> */}
                    <PhoneInput
                        country={"ru"}
                        value={values.phone}
                        onChange={(phone) =>
                            changeValue({
                                target: { id: "phone", value: phone },
                            })
                        }
                        containerClass="forms-input"
                        dropdownClass="forms-dropdown"
                    />
                </div>
                <div className="column">
                    <input
                        type="text"
                        placeholder="Ваш бюджет"
                        value={values.budget}
                        id="budget"
                        name="budget"
                        onChange={changeValue}
                    />
                </div>
                <div className="column">
                    <textarea
                        name="msg"
                        value={values.msg}
                        placeholder="Какое авто интересует?"
                        id="msg"
                        onChange={changeValue}
                    />
                </div>
            </div>
            <div className="rules">
                <div className="text">
                    Отправляя данную форму Вы соглашаетесь с
                    <a href="/politika-konfidentsialnosti">
                        Политикой конфиденциальности
                    </a>
                </div>
                <DefaultButton
                    text="Отправить"
                    arrow={false}
                    className="btn"
                    disable={
                        !values.name ||
                        !values.country ||
                        !values.phone ||
                        !values.budget ||
                        !values.msg
                    }
                    cb={{
                        type: "noty",
                        args: getMsg(),
                        cb: () => {
                            setValues({
                                name: "",
                                country: "",
                                phone: "",
                                budget: "",
                                msg: "",
                            });
                        },
                    }}
                />
            </div>
        </>
    );

    function getMsg() {
        let msg = `Большая форма\nurl: "${pathname}"\n\n`;
        msg += `Имя: ${values.name}\n`;
        msg += `Страна: ${values.country}\n`;
        msg += `Телефон: ${values.phone}\n`;
        msg += `Бюджет: ${values.budget}\n`;
        msg += `Сообщение: ${values.msg}\n`;

        return msg;
    }

    function changeValue({ target }) {
        setValues((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }
    function changeOptions(option) {
        setValues((prev) => ({
            ...prev,
            country: option,
        }));
    }
}
