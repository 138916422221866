"use client";

import { useRouter } from "next/navigation";

export default function Btn({ children, cb }) {
    const route = useRouter();
    return (
        <div
            className="btn"
            onClick={() => {
                const { type, args } = cb;
                if (type === "scroll") {
                    const { elem } = args;
                    var scrollDiv = document.querySelector(elem).offsetTop;
                    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
                }
                if (type === "link") {
                    const { link } = args;
                    route.push(link);
                }
            }}
        >
            {children}
        </div>
    );
}
