import { useRef, useState } from "react";

export default function SelectList({
    options = [],
    placeholder = "",
    onChange,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    const selectList = useRef(null);
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        selectList.current.blur();
        if (onChange) {
            onChange(option);
        }
    };

    return (
        <div
            className="select"
            tabIndex={1}
            ref={selectList}
            onBlur={(e) => {
                if (
                    e.relatedTarget !== null &&
                    e.currentTarget.contains(e.relatedTarget)
                )
                    return;
                setIsOpen(false);
            }}
        >
            <div
                onClick={() => {
                    setIsOpen((prev) => {
                        if (prev) {
                            selectList.current.blur();
                        }
                        return !prev;
                    });
                }}
            >
                {selectedOption ? selectedOption : placeholder}
            </div>
            {isOpen && (
                <ul className="select-list">
                    {options.map((option) => (
                        <li
                            key={option}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
